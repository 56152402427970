import React, { useEffect, useState } from 'react'
import AemPayloadError from '@/modules/AemPayloadError/AemPayloadError'
import ErrorBoundary from '../../pages/_app/HomePageErrorBoundary'
import HeroBanner from '../../common/components/HeroBanner/HeroBanner'
import { HomePageData } from '@/modules/home/home.model'
import IconBlockWrapper from '@/components/IconBlockWrapper/IconBlockWrapper'
import ProductsArrayWrapper from '@/components/ProductsArrayWrapper/ProductsArrayWrapper'
import FeaturesArrayWrapper from '@/components/FeaturesArrayWrapper/FeaturesArrayWrapper'
import SolutionsAreaOverview from '@/components/SolutionsAreaOverview/SolutionsAreaOverview'
import TestimonialWrapper from '@/components/TestimonialWrapper/TestimonialWrapper'
import BlockArrayWrapper from '@/components/BlockArrayWrapper/BlockArrayWrapper'
import styles from '@/modules/home/home.module.scss'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'

const Home = ({
  pageData,
  fetchError,
  errorData,
}: {
  pageData: HomePageData | null
  fetchError: boolean
  errorData: any
}) => {
  let pageContent: any[] = [],
    ele: any = ''

  if (!pageData || !Object.keys(pageData).length || fetchError) {
    const ele = (
      <AemPayloadError
        key="payload-error"
        id="aem-unreachable-message"
        message={errorData}
      />
    )
    pageContent.push(ele)
  }
  const [loggedInUser, setLoggedInUser] = useState<boolean>(false)
  const { status } = useGetUserInfoClientSide()
  let componentType: string

  if(pageData && Object.entries(pageData).length){
 
    for (let key in pageData) {

      // console.log(value)

      componentType = key.substring(0, key.replace(/\d+$/, '').length)

      switch (componentType) {
        case 'hero':
          ele = (
            <section key={key} id="hero-section">
              <HeroBanner
                key={key}
                title={pageData.hero.title}
                mainHead={pageData.hero.mainHead}
                shortDescription={pageData.hero.shortDescription}
                image={pageData.hero.image}
                cta1Link={loggedInUser ? '' : pageData.hero.cta1Link}
                cta1Text={loggedInUser ? '' : pageData.hero.cta1Text}
                cta2Link={pageData.hero.cta2Link}
                cta2Text={pageData.hero.cta2Text}
                displayView={pageData.hero.displayView}
              />
            </section>
          )
          pageContent.push(ele)
          break
  
        case 'iconBlockWrapper':
          ele = (
            <section key={key} id="textBlockWithIcon-section">
              <IconBlockWrapper
                key={key}
                gridOption={pageData[key].gridOption}
                textBlockWithIconParts={
                  pageData[key].textBlockWithIconParts
                }
                levelDownHeads={false}
              ></IconBlockWrapper>
            </section>
          )
          pageContent.push(ele)
          break
        
          case 'featureBlockWrapper':
            ele = (
              <section
                key={key}
                id="featureBlockWrapper-section"
                className={styles.sectionRow}
              >
                <FeaturesArrayWrapper
                  key={key}
                  title={pageData[key].title}
                  featureCategories={pageData[key].featureCategories}
                  defaultDisplayTag={pageData[key].defaultDisplayTag}
                  apiFeatureCFM={pageData[key].apiFeatureCFM}
                />
              </section>
            )
            pageContent.push(ele)
            break
  
        case 'homeSolutionsOverview':
          ele = (
            <section
              key={key}
              id="homeSolutionsOverview-section"
              className={styles.homeSolutionsOverviewSection}
            >
              <SolutionsAreaOverview
                key={key}
                solutionArea={pageData[key]}
              ></SolutionsAreaOverview>
            </section>
          )
  
          pageContent.push(ele)
          break
  
        case 'featureProducts':
          ele = (
            <section
              key={key}
              id="featureProducts-section"
              className={styles.sectionRow}
            >
              <ProductsArrayWrapper
                key={key}
                displayImage={true}
                title={pageData[key].title}
                shortDescription={pageData[key].shortDescription}
                productCardItems={pageData[key].productCardItems}
                marketingCardItems={pageData[key].marketingCardItems}
              />
            </section>
          )
          pageContent.push(ele)
          break
  
        case 'testimonial':
          ele = (
            <section
              key={key}
              id="testimonial-section"
              className={styles.testimonialSection}
            >
              <TestimonialWrapper
                key={key}
                title={pageData[key].title}
                shortDescription={pageData[key].shortDescription}
                testimonial={pageData[key].testimonial}
              />
            </section>
          )
          pageContent.push(ele)
          break

          case 'solutionsBlockWrapper':
          ele = (
            <section
              key={key}
              id="block-wrapper-section"
              className={styles.blockWrapperSection}
            >
              <BlockArrayWrapper
                title={pageData[key].title}
                shortDescription={pageData[key].shortDescription}
                textBlockWithImageParts={pageData[key].textBlockWithImageParts}
                gridOption={pageData[key].gridOption}
              />
            </section>
          )
        pageContent.push(ele)
        break

      }
    }
  
  }

  const [hydrated, setHydrated] = useState(false)
  useEffect(() => {
    if (status === 'unauthenticated') setLoggedInUser(false)
    else if (status === 'authenticated') setLoggedInUser(true)
    setHydrated(true)
  }, [status])

  return (
    <>
      {status !== 'loading' && (
        <ErrorBoundary>
          <>{hydrated && <div className="homeStripeOdd">{pageContent}</div>}</>
        </ErrorBoundary>
      )}
    </>
  )
}

export default Home
