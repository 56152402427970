import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import { FeatureItemData } from '@/components/FeaturesArrayWrapper/featuresArrayWrapper.model'
import styles from '@/components/FeaturesArrayWrapper/featuresArrayWrapper.module.scss'
import React, { useRef } from 'react'
import FeatureCard from './displayViews/FeatureCard'
import USBSelectableChips from '@usb-shield/react-selectable-chips'
import * as Tealium from '@/modules/tealium/Tealium'
import { useCookies } from 'react-cookie'

const fullWidthColumn = {
  ...defaultColLayoutOptions,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start'
}
let textColumn = {
  ...twelveColsOption,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4
  }
}

let defaultDisplayTagIndex: number;

const FeaturesArrayWrapper = ({
  title,
  featureCategories,
  defaultDisplayTag,
  apiFeatureCFM
}: FeatureItemData) => {

  const [cookies] = useCookies(['locale'])
  const defaultTag = defaultDisplayTag[0]

  featureCategories.forEach((featureCategory, index) => {
    if (defaultTag.id === featureCategory.id) {
      defaultDisplayTagIndex = index;
    }
  })

  if (typeof window !== 'undefined') {
    getRegionDisplayTag(cookies.locale)
  }
  
  function getRegionDisplayTag(cookieLocale: string) {
    if (cookieLocale === 'en-us') {
      defaultDisplayTagIndex = localStorage.getItem('displayTagIndexUS')
        ? parseInt(localStorage.getItem('displayTagIndexUS')!)
        : defaultDisplayTagIndex
    }
    if (cookieLocale === 'en-gb') {
      defaultDisplayTagIndex = localStorage.getItem('displayTagIndexGB')
        ? parseInt(localStorage.getItem('displayTagIndexGB')!)
        : defaultDisplayTagIndex
    }
  }

  if (typeof window !== 'undefined') {
    getRegionDisplayTag(cookies.locale)
  }

  const [selectedFeature, setSelectedFeature] = React.useState(defaultDisplayTagIndex);

  const featureCards = featureCategories.map((res, index) => {
    let active = index === selectedFeature;
    let feature = {
      id: res.id,
      label: res.title,
      active: active
    }
    let cards = apiFeatureCFM.filter((card: any) => card.featureTags.includes(res.id))
    return {feature, cards}
  });

  const options = featureCards.map(option => {
    return {
      label: option.feature.label,
      active: option.feature.active
    }
  })

  const tiles = featureCards.map(option => {
    return option.cards
  })

  const componentRef = useRef();

  const handleStatusUpdate = (e: any) => {

    let index = e?.options.map((option: any) => {
      return option.label;
    }).indexOf(e?.clickedRef);

    if (cookies.locale === 'en-us') {
      setSelectedFeature(index);
      localStorage.setItem("displayTagIndexUS", index)
    }
    if (cookies.locale === 'en-gb') {
      setSelectedFeature(index);
      localStorage.setItem("displayTagIndexGB", index)
    }

    const elems = Array.from(document.querySelectorAll('.chips-list__button'));
    elems?.forEach((elem: any) => {

      const spanEle = elem.childNodes[0];
      const svgEle = spanEle.childNodes[0];

      if (elem.innerText !== e?.clickedRef) {
        elem.classList.remove('active');
        elem.classList.remove('selected');
        elem.setAttribute('aria-pressed', false);

        elem.style.color='rgb(10, 65, 197)';
        elem.style.background='rgb(239, 243, 252)';
        spanEle.style.width='0rem';
        svgEle.style.width='0rem';
        svgEle.style.marginRight= '0rem';
      } else {
        elem.classList.add('active');
        elem.classList.add('selected');
        elem.setAttribute('aria-pressed', true);

        elem.style.color='white';
        elem.style.background= 'rgb(10, 65, 197)';
        svgEle.style.marginRight= '0.25rem';
        svgEle.style.width='1.25rem';
        svgEle.style.fill='rgb(255, 255, 255)';
        svgEle.style.color='rgb(255, 255, 255)';
        spanEle.style.width='1.25rem';
        spanEle.style.marginRight='0.25rem';
      }
    })

    Tealium.featureCategoryClickHandler(e?.clickedRef)
  }
  return (
    <USBGrid
      gridGap='normal'
      alignItems='start'
      columnCount='16'
      justifyContent='stretch'
      display='grid'
      padding='normal'
      addClasses={styles.gridWrapper}
    >
      <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
        {title && (
          <h2 className='headH2' data-testid='title'>
            {title}
          </h2>
        )}
      </USBColumn>
      <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
          <USBSelectableChips
            ref={componentRef}
            options={options}
            statusUpdateCallback={ handleStatusUpdate }
            headingLevel="h3"
            headingText=""
            />
          </USBColumn>

      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        <USBGrid
          gridGap='normal'
          alignItems='start'
          columnCount='12'
          justifyContent='stretch'
          display='grid'
          padding='zero'
          addClasses={styles.TwelveColSubGridFix}
        >
          <FeatureCard
            tiles={tiles[selectedFeature]}
          />
        </USBGrid>
      </USBColumn>
    </USBGrid>
  )
}

export default FeaturesArrayWrapper
